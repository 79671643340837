import React, { useCallback, useEffect, useState } from "react";
import { Flex, InputGroup, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import { Pagination } from "../../components/Pagination";
import PageLayout from "../../components/PageLayout/PageLayout";
import InputSearch from "../../components/InputSearch/index";
import { usePagination } from "../../hooks/usePagination";

import { brandsIsUpdated } from "../Brands/store/Brands.selectors";
import { getAllBrands } from "../Brands/store/Brands.thunk";
import { isCategoriesUpdated } from "../Categories/store/Categories/Categories.selectors";
import { gettingCategories } from "../Categories/store/Categories/Categories.thunk";
import { gettingSubcategories } from "../Categories/store/Subcategories/Subcategories.thunk";
import { protocolCategoriesIsUpdated } from "../ProtocolCategories/store/ProtocolCategories.selectors";

import { gettingProtocolCategories } from "../ProtocolCategories/store/ProtocolCategories.thunk";
import { getProducts, productsIsUpdated, productToast, toastIsView } from "./store/Products.selectors";
import { getAllProductsThunk } from "./store/Products.thunk";
import { ReadyProducts } from "./ReadyProducts";
import NotReadyProducts from "./notReadyProducts";
import { deleteToast } from "./store/Products.slice";
import { useCustomToast } from "../../hooks/useCustomToast";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { MAX_HEIGHT_BODY, SELECTED_TAB_STYLES } from "../../common/constants";
import { checkIsEnterKey } from "../../utils/checkIsEnterKey";
import { useSearchParams } from "../../hooks/useSearchParams";

const SEARCH_INPUT_BORDER_STYLE = { border: "1px solid #D0D3DA" };

const READY_PRODUCTS = 0;

const Products = () => {
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();

  const total = useSelector(getProducts())?.count;
  const isProductNeedUpdated = useSelector(productsIsUpdated());
  const isBrandNeedUpdated = useSelector(brandsIsUpdated());
  const isCategoryNeedUpdate = useSelector(isCategoriesUpdated());
  const isProtocolCategoryNeedUpdate = useSelector(protocolCategoriesIsUpdated());
  const productsToastInfo = useSelector(productToast());
  const productsToastIsViewed = useSelector(toastIsView());

  const [searchQuery, setSearchQuery] = useState();
  const [searchInput, setSearchInput] = useState("");

  const { handleGetSearchFieldValue, handleChangeSearchParams } = useSearchParams();
  const currentTab = +handleGetSearchFieldValue("currentTab") ?? 0;

  const { isPrevDisabled, isNextDisabled, page, limit, totalPages, nextPage, prevPage, resetPage, setPage, setLimit } =
    usePagination({
      total,
    });

  const submitSearchQuery = (event) => {
    if (checkIsEnterKey(event.key)) {
      setSearchQuery(searchInput);
    }
  };

  const cbProductsToast = useCallback(() => {
    if (productsToastInfo.view) {
      pushToast(productsToastInfo);
      dispatch(deleteToast());
    }
  }, [productsToastInfo, dispatch]);

  useUpdateEffect(() => {
    cbProductsToast();
  }, [productsToastIsViewed, cbProductsToast]);

  const cbGetProducts = useCallback(async () => {
    await dispatch(
      getAllProductsThunk({ limit, page: page || 1, isReady: currentTab === READY_PRODUCTS, query: searchQuery })
    ).unwrap();
  }, [limit, page, searchQuery, currentTab, dispatch]);

  const firstRequest = useCallback(async () => {
    try {
      await dispatch(
        getAllProductsThunk({ limit, page: page || 1, isReady: currentTab === READY_PRODUCTS, query: searchQuery })
      ).unwrap();
      await dispatch(gettingCategories()).unwrap();
      await dispatch(gettingSubcategories()).unwrap();
      await dispatch(gettingProtocolCategories()).unwrap();
      await dispatch(getAllBrands()).unwrap();
    } catch (e) {
      console.log("e", e);
    }
  }, [limit, page, searchQuery, currentTab, dispatch]);

  const handleDelete = () => {
    if (searchInput) {
      setSearchInput("");
      setSearchQuery(null);
    }
  };

  useEffect(() => {
    firstRequest();
  }, []);

  useUpdateEffect(() => {
    resetPage();
    setSearchQuery("");
    setSearchInput("");
  }, [currentTab, resetPage]);

  useUpdateEffect(() => {
    cbGetProducts();
  }, [limit, page, currentTab]);

  useUpdateEffect(() => {
    resetPage();
    cbGetProducts();
  }, [searchQuery]);

  useUpdateEffect(() => {
    if (isCategoryNeedUpdate) {
      dispatch(gettingCategories());
      dispatch(gettingSubcategories());
    }
  }, [isCategoryNeedUpdate, dispatch]);

  useUpdateEffect(() => {
    if (isProtocolCategoryNeedUpdate) {
      dispatch(gettingProtocolCategories());
    }
  }, [isProtocolCategoryNeedUpdate, dispatch]);

  useUpdateEffect(() => {
    if (isBrandNeedUpdated) {
      dispatch(getAllBrands());
    }
  }, [isBrandNeedUpdated, dispatch]);

  useUpdateEffect(() => {
    if (isProductNeedUpdated) {
      cbGetProducts();
    }
  }, [isProductNeedUpdated, cbGetProducts]);

  return (
    <PageLayout>
      <Flex mb="35px" width="100%" justifyContent="end">
        <InputGroup maxWidth="320px">
          <InputSearch
            style={SEARCH_INPUT_BORDER_STYLE}
            placeholder="Поиск"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={submitSearchQuery}
            onClick={() => setSearchQuery(searchInput)}
            onDelete={handleDelete}
          />
        </InputGroup>
      </Flex>
      <Tabs
        defaultIndex={currentTab}
        tabIndex={currentTab}
        height="calc(100% - 75px)"
        w="100%"
        onChange={(tab) => handleChangeSearchParams({ currentTab: tab })}
      >
        <TabList borderBottom="none" marginBottom="8px">
          <Flex width="100%" justifyContent="space-between">
            <Flex color="#737680">
              <Tab _selected={SELECTED_TAB_STYLES}>Готовые</Tab>
              <Tab _selected={SELECTED_TAB_STYLES}>Неготовые</Tab>
            </Flex>
            <Pagination
              totalPages={totalPages}
              page={page}
              limit={limit}
              isPrevDisabled={isPrevDisabled}
              isNextDisabled={isNextDisabled}
              onNextPage={nextPage}
              onPrevPage={prevPage}
              onChangePage={setPage}
              onLimitChange={setLimit}
            />
          </Flex>
        </TabList>

        <TabPanels height={MAX_HEIGHT_BODY}>
          <TabPanel height="100%" padding="16px 0px 0px 0px">
            <ReadyProducts currentTab={currentTab} />
          </TabPanel>
          <TabPanel height="100%" padding="16px 0px 0px 0px">
            <NotReadyProducts currentTab={currentTab} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
};
export default Products;
