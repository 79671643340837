import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import styled from "@emotion/styled";

import { ReactComponent as LoadFileIcon } from "../../assets/svg/LoadFileIcon.svg";

import { DeleteItemModal } from "../../components/DeleteModals";
import PageLayout from "../../components/PageLayout/PageLayout";
import { UploadInput } from "../../components/UploadInput/UploadInput";

import { brandsIsUpdated, brandToast, getBrands, isLoadingBrandsSelector, toastIsView } from "./store/Brands.selectors";
import { deleteToast } from "./store/Brands.slice";
import { addingBrand, deletingBrand, editingBrand, getAllBrands } from "./store/Brands.thunk";
import { BrandComponent } from "./BrandComponent";
import { removeSpaces } from "../../utils/removeSpaces";
import { useCustomToast } from "../../hooks/useCustomToast";

import { HINT_STYLE, MAX_HEIGHT_BODY, MAX_MARGIN } from "../../common/constants";
import { checkForFractionalOrIntValue } from "../../utils/checkForFractionalOrIntValue";
import { CONTAINER_STYLE, MAX_HEIGHT_BRAND_CONTAINER } from "./style";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";

const BrandsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: MAX_HEIGHT_BODY,
  gap: "24px",

  ".brand-table-edit-icon": {
    display: "none",
  },

  tr: {
    "&:hover .brand-table-edit-icon": {
      display: "block",
    },
  },

  td: {
    border: "none",
  },
});

const TheadColumns = [
  { name: "Логотип бренда", width: 20 },
  { name: "Название бренда", width: 50 },
  { name: "Наценка (%)", width: 10 },
  { name: "", width: 20 },
];

const Brands = () => {
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();

  const isNeedUpdated = useSelector(brandsIsUpdated());
  const isLoadingBrands = useSelector(isLoadingBrandsSelector());
  const toastInfo = useSelector(brandToast());
  const toastIsViewed = useSelector(toastIsView());
  const brands = useSelector(getBrands()).data;
  const brandNameList = brands && brands.map(({ name }) => name);

  const fileSelector = useRef();

  const [brandName, setBrandName] = useState("");
  const [brandExtraCharge, setBrandExtraCharge] = useState("");
  const [brandImage, setBrandImage] = useState(null);
  const [deleteBrand, setDeleteBrand] = useState(null);
  const [editState, setEditState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [newBrandError, setNewBrandError] = useState(null);

  const handleSelectFile = useCallback(
    (file) => {
      setBrandImage(file);
    },
    [setBrandImage]
  );

  const handleChangeNewName = (e) => {
    setNewBrandError({ ...newBrandError, name: null });
    setBrandName(e.target.value);
  };

  const handleChangeExtraCharge = ({ target: { value } }) => {
    if (value.replace(",", ".") > MAX_MARGIN) {
      return;
    }

    checkForFractionalOrIntValue(value, setBrandExtraCharge);
  };

  const editFieldState = useMemo(() => {
    return {
      ...editState,
      setId: (id) => setEditState({ ...editState, id }),
      reset: () => setEditState(null),
      resetError: (name) => setEditState({ ...editState, error: { ...editState.error, [name]: null } }),
      setError: (name, textError) => setEditState({ ...editState, error: { ...editState.error, [name]: textError } }),
    };
  }, [editState]);

  useEffect(() => {
    if (isNeedUpdated) {
      dispatch(getAllBrands());
      setBrandImage(null);
      setBrandName("");
      setBrandExtraCharge("");
      fileSelector.current.value = null;
    }
  }, [isNeedUpdated, fileSelector, dispatch]);

  useEffect(() => {
    if (toastInfo.view) {
      pushToast(toastInfo);
    }
    dispatch(deleteToast());
  }, [toastIsViewed, pushToast, dispatch]);

  const brandOnSubmit = async (icon, name, extraCharge, id) => {
    const newBrandsArray = brands.filter((item) => item.id !== id).map((item) => item.name);

    if (newBrandsArray.includes(name)) {
      editFieldState.setError("name", "Бренд с таким именем уже существует");
      return;
    }

    if (!name) {
      editFieldState.setError("name", "Поле обязательно к заполнению");
      return;
    }

    if (!extraCharge) {
      editFieldState.setError("extraCharge", "Поле обязательно к заполнению");
      return;
    }

    try {
      const extraChargeValue = parseFloat(extraCharge.replace(",", "."));
      if (Number.isNaN(extraChargeValue)) {
        throw new Error("error");
      }
      await dispatch(editingBrand({ id, name, icon, extraCharge: extraChargeValue }));
      editFieldState.reset();
    } catch (e) {
      console.log("=====>e", e);
      editFieldState.setError("extraCharge", "Наценка должна быть числовым значением");
    }
  };

  const addBrand = async () => {
    const name = removeSpaces(brandName);
    const extraCharge = parseFloat(brandExtraCharge.replace(",", "."));

    if (brandNameList.includes(name)) {
      setNewBrandError({ ...newBrandError, name: "Бренд с таким именем уже существует" });
      return;
    }

    if (!name) {
      setNewBrandError({ ...newBrandError, name: "Поле обязательно для заполнения" });
      return;
    }

    if (!extraCharge) {
      setNewBrandError({ ...newBrandError, extraCharge: "Поле обязательно для заполнения" });
      return;
    }

    if (brandImage && name) {
      try {
        const extraChargeValue = Number(extraCharge);
        if (Number.isNaN(extraChargeValue)) {
          throw new Error("error");
        }
        setIsLoading(true);
        await dispatch(addingBrand({ icon: brandImage, name, extraCharge: extraChargeValue }));
        setNewBrandError(null);
      } catch (e) {
        setNewBrandError({ ...newBrandError, extraCharge: "Наценка должна быть числовым значением" });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDeleteBrand = (brand) => {
    setDeleteBrand(brand);
  };

  const handleConfirmDeleteBrand = (id) => {
    dispatch(deletingBrand(id));
  };

  const handleCloseDeleteModal = () => {
    setDeleteBrand(null);
  };

  const handleEnterKey = (event) => {
    const name = removeSpaces(brandName);
    if (event.key === "Enter") {
      if (brandImage && name) {
        addBrand();
      }
      event.target.blur();
    }
  };

  const brandRow = (brand) => (
    <BrandComponent
      key={`brand-${brand.id}`}
      brand={brand}
      isEditMode={brand.id === editFieldState?.id}
      onDelete={handleDeleteBrand}
      onSubmit={brandOnSubmit}
      editState={editFieldState}
    />
  );

  return (
    <PageLayout>
      <BrandsContainer>
        <Flex gap={1} position="relative">
          <FormControl isInvalid={newBrandError?.name}>
            <Input
              placeholder="Введите название бренда"
              value={brandName}
              onChange={handleChangeNewName}
              onKeyDown={handleEnterKey}
              autoComplete="off"
            />
            <FormErrorMessage>{newBrandError?.name}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={newBrandError?.extraCharge}>
            <Input
              placeholder="Введите наценку от 0 до 100"
              value={brandExtraCharge}
              onChange={handleChangeExtraCharge}
              onKeyDown={handleEnterKey}
              autoComplete="off"
            />
            <FormErrorMessage>{newBrandError?.extraCharge}</FormErrorMessage>
          </FormControl>

          <InputGroup>
            <Input
              placeholder="Загрузите логотип"
              value={brandImage?.name || ""}
              isReadOnly
              style={{ position: "relative" }}
              onClick={() => fileSelector.current.click()}
            />
            <Text style={{ position: "absolute", top: "40px", whiteSpace: "nowrap", ...HINT_STYLE }}>
              Размер логотипа - 500x500 px, до 400 Kb, PNG, JPG, JPEG
            </Text>
            <InputRightElement>
              <Box>
                <UploadInput
                  ref={fileSelector}
                  formats={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={400}
                  onChangeFile={handleSelectFile}
                />
                <IconButton
                  height="14px"
                  w="14px"
                  icon={<LoadFileIcon />}
                  variant="unstyled"
                  aria-label="Load icon"
                  onClick={() => fileSelector.current.click()}
                />
              </Box>
            </InputRightElement>
          </InputGroup>

          <Button
            w="100%"
            marginLeft="auto"
            colorScheme="purple"
            isLoading={isLoading}
            isDisabled={!(brandName && brandImage && brandExtraCharge)}
            onClick={addBrand}
          >
            Добавить бренд
          </Button>
        </Flex>
        <Box maxH={MAX_HEIGHT_BRAND_CONTAINER}>
          <TableConstructor
            items={brands}
            theadItems={TheadColumns}
            Row={brandRow}
            containerStyles={CONTAINER_STYLE}
            isLoading={isLoadingBrands}
          />
        </Box>
      </BrandsContainer>

      {deleteBrand && (
        <DeleteItemModal
          label="бренд"
          item={deleteBrand}
          descripion="При удалении бренда будут БЕЗВОЗВРАТНО удалены все связанные с ним протоколы, принадлежащие данному бренду продукты - перейдут в неготовые."
          onClose={handleCloseDeleteModal}
          onDelete={handleConfirmDeleteBrand}
        />
      )}
    </PageLayout>
  );
};

export default Brands;
