import { format } from "date-fns/esm";
import { DateTime } from "luxon";
import * as yup from "yup";
import { DEFAULT_DATE_FORMAT } from "../../common/constants";

export const paymentMethods = {
  ONLINE: "Оплата онлайн",
  OFFICE_CASH: "Оплата наличными в офисе",
  OFFICE_TERMINAL: "Оплата картой в офисе",
  COURIER_CASH: "Оплата наличными курьеру",
  COURIER_TERMINAL: "Оплата картой курьеру",
};

export const PAYMENT_OPTIONS = [
  { value: "OFFICE_CASH", label: "Оплата наличными в офисе" },
  { value: "OFFICE_TERMINAL", label: "Оплата картой в офисе" },
  { value: "COURIER_CASH", label: "Оплата наличными курьеру" },
  { value: "COURIER_TERMINAL", label: "Оплата картой курьеру" },
];

export const PAYMENT_OPTIONS_OFFICE = [
  { value: "OFFICE_CASH", label: "Оплата наличными в офисе" },
  { value: "OFFICE_TERMINAL", label: "Оплата картой в офисе" },
];

export const PAYMENT_OPTIONS_COURIER = [
  { value: "COURIER_CASH", label: "Оплата наличными курьеру" },
  { value: "COURIER_TERMINAL", label: "Оплата картой курьеру" },
];

export const DELIVERY = "DELIVERY";

export const BUTTON_STYLE = {
  color: `#C06ECC`,
  cursor: "pointer",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
};

export const CHANGE_BUTTON_STYLE = {
  color: `#C06ECC`,
  cursor: "pointer",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  border: "none",
  padding: 0,
};

export const ICON_PLUS_STYLE = {
  backgroundColor: "transparent",
  color: `#C06ECC`,
  width: "20px",
  height: "20px",
};

export const DELIVERY_STASUSES = [
  { value: "PREPARING", label: "Сбор заказа" },
  { value: "IN_TRANSIT", label: "В доставке" },
  { value: "DELIVERED", label: "Доставлен" },
];

export const DELIVERY_VALIDATION_SCHEMA = yup.object().shape({
  deliveryDate: yup
    .date()
    .required("Дата обязательное поле")
    .test({
      message: `Дата доставки должна быть позже ${DateTime.now().setLocale("ru").minus({ day: 1 }).toFormat("d MMMM")}`,
      test: (field) => DateTime.fromJSDate(field).plus({ day: 1 }).toMillis() > DateTime.now().toMillis(),
    })
    .nullable(),
  timeStart: yup.string().min(1).required("Время начала обязательное поле").nullable(),
  timeEnd: yup
    .string()
    .min(1)
    .required("Время окончания обязательное поле")
    .test({
      message: "Время окончания должно быть позже времени начала",
      test: (field, parent) => (parent.parent.timeStart ? field >= parent.parent.timeStart : true),
    })
    .nullable(),
});
