import * as yup from "yup";
import { numbersWithLetters, onlyEngLetters, onlyNumberWithoutCyrillic } from "../../utils/regexp";

export const PROMOCODE_VALIDATION_SCHEMA = (promocodes) => {
  const getPromocodesNames = (
    id,
    clientType // NOTE: clientType === ('RETAIL' || 'SPECIALIST')
  ) =>
    promocodes
      .filter((promocode) => promocode.availableFor === clientType && promocode.id !== id)
      .map((promocode) => promocode.name);

  const getPromocodesCodes = (
    id,
    clientType // NOTE: clientType === ('RETAIL' || 'SPECIALIST')
  ) =>
    promocodes
      .filter((promocode) => promocode.availableFor === clientType && promocode.id !== id)
      .map((promocode) => promocode.promocode);

  const getIdOfPromocode = (promocode) => {};

  return yup.object().shape({
    name: yup
      .string()
      .required('Поле "Название" обязательное ')
      .test({
        name: "isUnique",
        message: "Промокод с таким названием уже существует",
        test: (name, { parent }) => !getPromocodesNames(parent.id, parent.availableFor.value).includes(name),
      }),
    promocode: yup
      .string()
      .required('Поле "Промокод" обязательное ')
      .test("promocode", "Промокод должен содержать латиницу и/или цифры", (promoCode) => {
        if (!promoCode) {
          return true;
        }
        if (promoCode.match(numbersWithLetters)) {
          return true;
        }
        if (promoCode.match(onlyNumberWithoutCyrillic)) {
          return true;
        }
        if (promoCode.match(onlyEngLetters)) {
          return true;
        }
        return false;
      })
      .min(3, "Должен содержать минимум 3 символа")
      .max(12, "Должен содержать максимум 12 символов")
      .test(
        "promocode",
        "Такой промокод уже существует",
        (promocode, { parent }) => !getPromocodesCodes(parent.id, parent.availableFor.value).includes(promocode)
      ),
    percent: yup.number().required('Поле "Скидка" обязательное').positive("Скидка должна быть больше нуля"),
    availableFor: yup.object().nullable().required('Поле "Доступен для" обязательное'),
    products: yup.array(),
    category: yup.object().nullable(),
    subcategory: yup.mixed().when("category", {
      is: (category) => !!category,
      then: yup.mixed().required("Подкатегория обязательна к заполнению"),
    }),
    startDate: yup
      .date()
      .required("Заполните поле")
      .test(
        "no min date error if we updating promocode",
        "Не может быть раньше текущей даты",
        (startDate, { parent }) => {
          if (parent.id) {
            return true;
          }

          return startDate > new Date();
        }
      ),
    endDate: yup
      .date()
      .required("Заполните поле")
      .min(yup.ref("startDate"), "Дата не может быть раньше начальной даты"),
  });
};
