import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Set } from "immutable";
import { Checkbox, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";

import { PopUp } from "../../components/PopUp";
import RowReadyProduct from "./RowReadyProduct";

import { getProducts, isPending } from "./store/Products.selectors";
import { updateProductThunk } from "./store/Products.thunk";
import { CONTAINER_STYLE } from "../Users/style";
import { TheadColumns } from "./constants";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "../../hooks/useSearchParams";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";

const NotReadyProducts = ({ currentTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isStatusPending = useSelector(isPending());

  const products = useSelector(getProducts()).data;
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState(new Set());
  const { searchParamsString } = useSearchParams();

  useEffect(() => {
    setCheckedItems(new Set());
    setCheckedAll(false);
  }, [currentTab]);

  const onSubmitSuccess = async (productsToSend) => {
    await dispatch(updateProductThunk(productsToSend)).unwrap();
    setCheckedItems(checkedItems.clear());
    setCheckedAll(false);
  };

  const openRequestModal = ({ id }) => navigate({ pathname: id, search: searchParamsString });

  const checkAll = () => {
    products.forEach(({ id }) => setCheckedItems((set) => set.add(id)));
    setCheckedAll(true);
  };

  const uncheckAll = () => {
    setCheckedItems((set) => set.clear());
    setCheckedAll(false);
  };

  useEffect(() => {
    setCheckedAll(checkedItems.size === products?.length);
  }, [checkedItems]);

  const isIndeterminate = checkedItems.some(Boolean) && !isCheckedAll;

  const onCheckItemClicked = useCallback(
    (id) => () => {
      if (checkedItems.has(id)) {
        setCheckedItems(checkedItems.remove(id));
      } else {
        setCheckedItems(checkedItems.add(id));
      }
    },
    [checkedItems]
  );

  const onHandleClearItems = () => {
    setCheckedItems(checkedItems.clear());
  };

  const renderRow = (product) => {
    const { id, nameFrom1C, codeFrom1C } = product;

    return (
      <RowReadyProduct
        id={id}
        checkedItems={checkedItems}
        onCheckItemClicked={onCheckItemClicked}
        openRequestModal={openRequestModal}
        product={product}
        nameFrom1C={nameFrom1C}
        codeFrom1C={codeFrom1C}
      />
    );
  };

  return (
    <TableConstructor
      isLoading={isStatusPending}
      theadItems={TheadColumns}
      theadExtraComponent={
        <Th textAlign="center" lineHeight={0}>
          <Checkbox
            isChecked={isCheckedAll}
            isIndeterminate={isIndeterminate}
            onChange={isCheckedAll ? uncheckAll : checkAll}
          />
        </Th>
      }
      containerStyles={CONTAINER_STYLE}
      extraComponent={
        checkedItems.size > 0 && (
          <PopUp
            onSubmitSuccess={onSubmitSuccess}
            checkedItems={checkedItems}
            isPending={isStatusPending}
            onClose={onHandleClearItems}
          />
        )
      }
      notFoundText="Здесь пока нет товаров"
      items={products}
      Row={renderRow}
    />
  );
};

export default NotReadyProducts;
