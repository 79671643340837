import * as yup from "yup";
import { checkCode1C } from "../../services/productService";
import { checkIsUniq } from "../../utils/checkIsUniq";

export const MAX_CHARACTERISTIC = 11;

yup.addMethod(yup.array, "unique", function (message, mapper = (a) => a?.name) {
  return this.test("unique", message, (list) => {
    return list.length === new Set(list.map(mapper)).size;
  });
});

export const VALIDATION_PRODUCT_SCHEMA = yup.object({
  name: yup.string().required('Поле "Название" обязательное'),
  description: yup.string().required('Поле "Описание" обязательное'),
  images: yup
    .array()
    .min(1, "Не меньше 1 изображения")
    .max(5, "Не больше 5 изображений")
    .required('Поле "Изображения" обязательное '),
  categoryProduct: yup
    .array()
    .min(1)
    .of(
      yup
        .object()
        .shape({
          category: yup.mixed().required('Поле "Категория" обязательное'),
          sub_catalog_product: yup
            .mixed()
            .required('Поле "Подкатегория" обязательное')
            .test({
              message: 'Поля "Подкатегория" должны быть уникальными',
              name: "isUniq",
              test: (field, parent) => {
                const currentId = field?.id;
                const allSubcategoriesId = parent.from
                  ?.at(-1)
                  ?.value?.categoryProduct?.map((category) => category?.sub_catalog_product?.id);

                return checkIsUniq(currentId, allSubcategoriesId);
              },
            }),
        })
        .nullable()
    )
    .nullable(),
  purpose: yup.object().required('Поле "Назначение" обязательное').nullable(),
  brand: yup.object().required('Поле "Бренд" обязательное').nullable(),
  sub_category_protocol: yup.array(),
  characteristics: yup
    .array()
    .max(MAX_CHARACTERISTIC, "Не больше 15 характеристик")
    .of(
      yup.object().shape({
        key: yup.string().trim().required("Поле обязательно"),
        value: yup.string().trim().required("Поле обязательно"),
      })
    ),
  currentVolume: yup.string().required('Поле "Объем" обязательное'),
  charCountry: yup.string(),
  charProductCode: yup.string(),
  charSkinType: yup.string(),
  charAge: yup.string(),
  tags: yup.array().max(120).unique("Теги должны быть уникальные"),
  similar: yup.array(),
  isRetailAllowed: yup.boolean(),
  volume: yup.array().of(
    yup.object().shape({
      codeFrom1C: yup
        .string()
        .required("Поле артикул не должно быть пустым")
        .test({
          message: "Артикулы не должны совпадать",
          name: "isUniq",
          test: (field, parent) => {
            const { codeFrom1C } = parent.from.at(-1).value;

            const allCatalogs = parent.from
              .at(-1)
              .value.volume.map((volume) => volume.codeFrom1C)
              .filter((el) => !!el);

            return checkIsUniq(field, [codeFrom1C, ...allCatalogs]);
          },
        })
        .test({
          message: "Неверный артикул",
          name: "isValid",
          test: async (field) => {
            try {
              await checkCode1C(field.trim());
              return true;
            } catch (error) {
              return false;
            }
          },
        }),
      volume: yup.string().trim().required("Поле объема не должно быть пустым"),
    })
  ),
});

export const EXCLUDED_CHARACTERISTICS = ["Страна-производитель", "Код товара", "Тип кожи", "Возрасты", "Состав"];

export const SKIN_TYPES = [
  { label: "сухая кожа", value: "сухая кожа" },
  { label: "жирная кожа", value: "жирная кожа" },
  { label: "комбинированная кожа", value: "комбинированная кожа" },
  { label: "чувствительная кожа", value: "чувствительная кожа" },
  { label: "увядающая кожа", value: "увядающая кожа" },
  { label: "обезвоженная кожа", value: "обезвоженная кожа" },
  { label: "проблемная кожа", value: "проблемная кожа" },
  { label: "куперозная кожа", value: "куперозная кожа" },
  { label: "нормальная кожа", value: "нормальная кожа" },
];

export const AGES = [
  { label: "20+", value: "20+" },
  { label: "30+", value: "30+" },
  { label: "40+", value: "40+" },
  { label: "50+", value: "50+" },
  { label: "60+", value: "60+" },
];

export const PURPOSES = [
  { value: "Дом", label: "Дом" },
  { value: "Проф", label: "Проф" },
];

export const PURPOSES_REQUEST = [
  { value: "cosmetolog", label: "Косметолог" },
  { value: "medic", label: "Медик" },
];

export const DEFAULT_PURPOSES_REQUEST = { value: "cosmetolog", label: "Косметолог" };

export const ROW_STYLE = {
  marginTop: "0",
  marginBottom: "12px",
};

export const ADD_BUTTON_STYLE = {
  display: "flex",
  color: "#C06ECC",
  fontWeight: "500",
  fontSize: "16px",
  cursor: "pointer",
};

export const TheadColumns = [
  { name: "Название", width: 80 },
  { name: "Артикул", width: 15 },
];

export const ADD_TEXT_STYLE = { color: `purple.500`, cursor: "pointer", fontWeight: "500", fontSize: "14px" };
