import RowOrder from "./RowOrder";

import { generateName } from "./utils/generateName";
import { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";
import { useSelector } from "react-redux";
import { isOrdersLoading } from "./store/Orders.selectors";
import { getCurrentUserState } from "../Login/store/Login.selectors";
import { checkIsRoleAnalytic } from "../../utils/roles";

const TheadColumns = [
  { name: "Покупатель", width: 25 },
  { name: "Склад", width: 15 },
  { name: "Способ получения", width: 15 },
  { name: "Дата и время оформления", width: 20 },
  { name: "Сумма заказа", width: 15 },
  { name: "Оплачено", width: 10 },
];

const OrdersTable = ({ orders, request, isActual }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { role } = useSelector(getCurrentUserState);
  const isLoading = useSelector(isOrdersLoading());

  const handleSelectedOrder = (orderId) => {
    try {
      navigate({ pathname: orderId, search: searchParams.toString() });
      request(orderId);
    } catch (e) {
      console.log(e);
    }
  };

  const tableHeadColumns = useMemo(() => (isActual ? TheadColumns : TheadColumns.slice(0, 5)), [isActual]);

  const orderRow = (order) => {
    const { id, user, delivery_type: deliveryType, date, total, isViewedByAdmin, isPayed, delivery } = order;
    const fullName = generateName(user);
    return (
      <RowOrder
        key={id}
        orderId={id}
        isActual={isActual}
        isViewedByAdmin={checkIsRoleAnalytic(role) || isViewedByAdmin}
        fullName={fullName}
        orderNumber={order?.warehouse?.city}
        deliveryType={deliveryType}
        delivery={delivery}
        date={date}
        total={total}
        onSelectOrder={handleSelectedOrder}
        isPayed={isPayed}
      />
    );
  };

  return (
    <TableConstructor
      isLoading={isLoading}
      items={orders}
      Row={orderRow}
      notFoundText="Здесь пока нет заказов"
      theadItems={tableHeadColumns}
      containerStyles={{ scrollbarGutter: "stable", height: "100%", overflowY: "auto" }}
    />
  );
};

export default OrdersTable;
