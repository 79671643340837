import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Set } from "immutable";
import { Checkbox, Th } from "@chakra-ui/react";

import { PopUp } from "../../components/PopUp";

import RowReadyProduct from "./RowReadyProduct";
import { getProducts, isPending } from "./store/Products.selectors";
import { updateProductThunk } from "./store/Products.thunk";

import { CONTAINER_STYLE } from "../Users/style";
import { TheadColumns } from "./constants";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "../../hooks/useSearchParams";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";

export const ReadyProducts = (currentTab) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isStatusPending = useSelector(isPending());

  const products = useSelector(getProducts()).data;
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState(new Set());
  const { searchParamsString } = useSearchParams();

  useEffect(() => {
    setCheckedItems(new Set());
    setCheckedAll(false);
  }, [currentTab]);

  const onSubmitSuccess = async (productsToSend) => {
    await dispatch(updateProductThunk(productsToSend)).unwrap();
    setCheckedItems(checkedItems.clear());
    setCheckedAll(false);
  };

  const openRequestModal = ({ id }) => navigate({ pathname: id, search: searchParamsString });

  const checkAll = () => {
    products.forEach(({ id }) => setCheckedItems((set) => set.add(id)));
    setCheckedAll(true);
  };

  const uncheckAll = () => {
    setCheckedItems((set) => set.clear());
    setCheckedAll(false);
  };

  useEffect(() => {
    setCheckedAll(checkedItems.size === products?.length);
  }, [checkedItems]);

  const isIndeterminate = checkedItems.some(Boolean) && !isCheckedAll;

  const onCheckItemClicked = (id) => () => {
    if (checkedItems.has(id)) {
      setCheckedItems(checkedItems.remove(id));
    } else {
      setCheckedItems(checkedItems.add(id));
    }
  };

  const onHandleClearItems = () => {
    setCheckedItems(checkedItems.clear());
  };

  const handleRenderRow = (product) => (
    <RowReadyProduct
      id={product.id}
      checkedItems={checkedItems}
      onCheckItemClicked={onCheckItemClicked}
      openRequestModal={openRequestModal}
      product={product}
      name={product.name}
      nameFrom1C={product.nameFrom1C}
      codeFrom1C={product.codeFrom1C}
    />
  );

  return (
    <TableConstructor
      theadItems={TheadColumns}
      isLoading={isStatusPending}
      isNotFound={products?.length === 0}
      notFoundText="Здесь пока нет товаров"
      items={products}
      Row={handleRenderRow}
      containerStyles={CONTAINER_STYLE}
      extraComponent={
        !!checkedItems.size && (
          <PopUp
            isPending={isStatusPending}
            onClose={onHandleClearItems}
            checkedItems={checkedItems}
            onSubmitSuccess={onSubmitSuccess}
          />
        )
      }
      theadExtraComponent={
        <Th textAlign="center" lineHeight={0}>
          <Checkbox
            isChecked={isCheckedAll}
            isIndeterminate={isIndeterminate}
            onChange={isCheckedAll ? uncheckAll : checkAll}
          />
        </Th>
      }
    />
  );
};

export default ReadyProducts;
