import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  InputGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import PageLayout from "../../components/PageLayout/PageLayout";
import { Pagination } from "../../components/Pagination";
import InputSearch from "../../components/InputSearch";
import ClientsTable from "./ClientsTable";

import { clientToast, getClients, toastIsView } from "./store/Clients.selectors";
import { deleteToast } from "./store/Clients.slice";
import { useCustomToast } from "../../hooks/useCustomToast";
import { createReportClientsThunk, getClientsThunk } from "./store/Clients.thunk";
import { usePagination } from "../../hooks/usePagination";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { checkMobileDevice } from "../../utils/checkMobileDevice";
import { onlyPhoneNumber } from "../../utils/regexp";
import { checkForNumericValue } from "../../utils/checkForNumericValue";
import { EXPORT_FILE_BTN } from "../Products/style";
import { InputSearchMobile, InputSearchNotMobile, TabListMobile, TabListNotMobile } from "./style";
import { MAX_HEIGHT_BODY } from "../../common/constants";

const isMobile = checkMobileDevice();

const TABS = {
  0: "SPECIALIST",
  1: "RETAIL",
};

const Clients = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentTab, setCurrentTab] = useState(location.state ? location.state.currentTab : 0);
  const [searchError, setSearchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { pushToast } = useCustomToast();

  const users = useSelector(getClients())?.data;
  const total = useSelector(getClients())?.count;
  const clientsToastInfo = useSelector(clientToast());
  const clientsToastInfoIsViewed = useSelector(toastIsView());

  const { isPrevDisabled, isNextDisabled, page, limit, totalPages, nextPage, prevPage, resetPage, setPage, setLimit } =
    usePagination({
      total,
    });

  const handleChangeSearch = ({ target: { value } }) => {
    if (searchError) {
      setSearchError(null);
    }
    checkForNumericValue(value, setSearchInput);
  };

  const handleKeyDownSearchQuery = (event) => {
    const { value } = event.target;
    if (event.key === "Enter") {
      if (value.length < 3 || value.length > 13) {
        setSearchError("Номер должен содержать от 3 до 13 символов");
        return;
      }
      setSearchQuery(searchInput.replace(onlyPhoneNumber, ""));
    }
  };

  const handleClickSearchQuery = () => {
    if (searchInput.length < 3 || searchInput.length > 13) {
      setSearchError("Номер должен содержать от 3 до 13 символов");
      return;
    }
    setSearchQuery(searchInput.replace(onlyPhoneNumber, ""));
  };

  const handleDeleteSearchValue = () => {
    setSearchInput("");
    setSearchQuery(null);
    setSearchError(null);
  };

  const handleExportToExcel = async () => {
    try {
      setIsLoading(true);
      await dispatch(createReportClientsThunk({ report: { role: TABS[currentTab] } })).unwrap();
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  };

  const cbClientsToast = useCallback(() => {
    if (clientsToastInfo.view) {
      pushToast(clientsToastInfo);
      dispatch(deleteToast());
    }
  }, [pushToast, clientsToastInfo, dispatch]);

  const requestUsersList = useCallback(async () => {
    await dispatch(getClientsThunk({ limit, page: page || 1, role: TABS[currentTab], query: searchQuery })).unwrap();
  }, [limit, page, searchQuery, currentTab, dispatch]);

  useEffect(() => {
    requestUsersList();
  }, [requestUsersList]);

  useUpdateEffect(() => {
    resetPage();
    setSearchQuery("");
    setSearchInput("");
  }, [currentTab, resetPage]);

  useUpdateEffect(() => {
    requestUsersList();
  }, [requestUsersList]);

  useUpdateEffect(() => {
    cbClientsToast();
  }, [cbClientsToast, clientsToastInfoIsViewed]);

  return (
    <PageLayout>
      <Flex style={!isMobile ? InputSearchNotMobile : InputSearchMobile}>
        <Button
          onClick={handleExportToExcel}
          style={EXPORT_FILE_BTN}
          colorScheme="purple"
          isLoading={isLoading}
          aria-label="Export"
        >
          Сформировать отчет
        </Button>
        <InputGroup style={!isMobile ? { maxWidth: "320px" } : { width: "100%" }}>
          <FormControl isInvalid={searchError}>
            <InputSearch
              style={{ border: "1px solid #D0D3DA" }}
              placeholder="Поиск по номеру телефона"
              value={searchInput}
              onChange={handleChangeSearch}
              onKeyDown={handleKeyDownSearchQuery}
              onClick={handleClickSearchQuery}
              onDelete={handleDeleteSearchValue}
            />
            <FormErrorMessage style={{ whiteSpace: "nowrap", position: "absolute" }}>{searchError}</FormErrorMessage>
          </FormControl>
        </InputGroup>
      </Flex>

      <Tabs index={currentTab} height="calc(100% - 75px)" w="100%" onChange={setCurrentTab}>
        <TabList borderBottom="none" marginBottom="8px">
          <Flex style={!isMobile ? TabListNotMobile : TabListMobile}>
            {!isMobile && (
              <Flex color="#737680">
                <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Специалисты</Tab>
                <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Розничные пользователи</Tab>
              </Flex>
            )}

            <Pagination
              totalPages={totalPages}
              page={page}
              limit={limit}
              isPrevDisabled={isPrevDisabled}
              isNextDisabled={isNextDisabled}
              onNextPage={nextPage}
              onPrevPage={prevPage}
              onChangePage={setPage}
              onLimitChange={setLimit}
            />
          </Flex>
        </TabList>

        <TabPanels height={MAX_HEIGHT_BODY}>
          <TabPanel height="100%" p="16px 0 0 0">
            <ClientsTable users={users} currentTab={currentTab} />
          </TabPanel>
          <TabPanel height="100%" p="16px 0 0 0">
            <ClientsTable users={users} currentTab={currentTab} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
};
export default Clients;
