import { useRef } from "react";
import { Checkbox, Td, Tooltip, Tr } from "@chakra-ui/react";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";

import { useIsOverflow } from "../../hooks/hooks";
import { keyHandler } from "../../utils/keyHandler";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";

const CHECKBOX_COLUMN_STYLE = { maxWidth: 48, width: 48, padding: "14px 16px" };
const ARTICUL_COLUMN_STYLE = { maxWidth: 180, width: 180 };

const RowReadyProduct = ({
  id,
  name,
  nameFrom1C,
  codeFrom1C,
  checkedItems,
  onCheckItemClicked,
  openRequestModal,
  product,
}) => {
  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);

  const handleSelectProduct = (event) => keyHandler(event.key, () => openRequestModal(product));

  return (
    <Tr key={`products-${id}`} maxH="60px">
      <Td textAlign="center" lineHeight={0}>
        <Checkbox isChecked={checkedItems.has(id)} onChange={onCheckItemClicked(id)} />
      </Td>

      <Td
        cursor="pointer"
        tabIndex={0}
        style={TEXT_OVERFLOW_STYLE}
        onKeyDown={handleSelectProduct}
        onClick={() => openRequestModal(product)}
        ref={ref}
      >
        <TooltipWithContent text={name || nameFrom1C || "-"} isOverflow={isOverflow} />
      </Td>
      <Td style={ARTICUL_COLUMN_STYLE}>{codeFrom1C || "-"}</Td>
    </Tr>
  );
};

export default RowReadyProduct;
